.goodVideo{
    margin-top: 20px;
    width: 1000px;
    overflow: hidden;
}
.goodVideoBox{
    width: 1020px;
    margin-top: 15px;
    overflow: hidden;
}
.goodVideoItem{
    float: left;
    margin-right: 20px;
    width: 235px;
    text-decoration: none;
}
.goodVideoItem:hover{
    text-decoration: none;
}
.goodVideoImage{
    width: 235px;
    height: 133px;
    position: relative;
}
.goodVideoPic{
    width: 235px;
    height: 133px;
}
.goodVideoIcon{
    width: 35px;
    height: 35px;
    position: absolute;
    left: 83px;
    top: 39px;
    background: url(https://x0.ifengimg.com/ucms/2022_38/EF05D5AAE01E854CB69CF52E1C6929695564DCAC_size1_w35_h35.png);
}
.goodVideoTitle{
    margin-top: 10px;
    font-size: 16px;
    color: #212223;
    line-height: 22px;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-decoration: none;
    margin-bottom: 5px;
}
.goodVideoTitle:hover{
    color:#092F91;
    text-decoration: none;
}