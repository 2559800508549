.lunbo {
    /* outline: 1px solid blue; */
    margin-top: 20px;
    overflow: hidden;
    width: 480px;
    height: 270px;
    float: left;
    margin-right: 40px;
    & [ifeng_ui_pc_sliders-dots] div {
        margin: 0 3px;
        width: 12px;
        height: 3px;
    }
    & [ifeng_ui_pc_sliders-dots] {
        /* outline: 1px solid red; */
        position: absolute;
        z-index: 11;
        bottom: 15px;
    }
    & [ifeng_ui_pc_sliders-title] {
        /* outline: 1px solid red; */
        background: rgba(0,0,0,0.65);
    }
    & [ifeng_ui_pc_sliders-arrows_pre]{
        width: 24px;
        height: 50px;
        margin-top: -25px;
    }
    & [ifeng_ui_pc_sliders-arrows_next]{
        width: 24px;
        height: 50px;
        margin-top: -25px;
    }
    & [ifeng_ui_pc_sliders-image]{
        width: 480px;
        height: 270px;
    }
    & [ifeng_ui_pc_sliders-link]{
        height: 270px;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.70) 100%);
    }
    & [ifeng_ui_pc_sliders-title] {
        height: 48px;
        padding: 10px 10px 0;
        background:none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.70) 100%);
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
    }
    & [ifeng_ui_pc_sliders-dots] {
        height: 4px;
        bottom: 8px;
        text-align: center;
    }
    & [ifeng_ui_pc_sliders-dots] div {
        width: 4px;
        height: 4px;
        background: rgba(255,255,255,0.50);
        border-radius: 50%;
    }
    & .dotCurrent {
        width: 12px !important;
        height: 4px !important;
        background:#fff !important;
        border-radius: 2px !important;
    }
}
.box,
.image {
    height: 360px;
}
.image {
    width: 640px;
}
.link {
    height: 34px;
    /* font: 20px '微软雅黑'; */
    line-height: 34px;
    color: #fff;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}

