.imageHead{
    width: 1000px;
    height: 45px;
    background: url(https://x0.ifengimg.com/ucms/2022_39/BD71574AD5C0947FFE0BB360321899F1A2FABE91_size29_w1000_h45.png) no-repeat 0 0;
    overflow: hidden;
}
.imageHeadTitle{
    float: left;
    font-weight: bold;
    font-size: 18px;
    line-height: 45px;
    color: #ffffff;
    padding-left: 10px;
}
.imageHeadMore{
    float:right;
    margin-right: 15px;
    padding-right: 14px;
    height: 45px;
    line-height: 47px;
    font-size: 12px;
    color: #fff;
    background:url(https://x0.ifengimg.com/ucms/2022_38/8335123620A1174E35F2A6232154F04B6FBD22AF_size1_w12_h12.png) no-repeat right center;
}
.imageHeadMore:hover{
    text-decoration: underline;
}
