.nav{
    overflow: hidden;
    background:#092F91;
    height: 40px;
}
.navBox{
    width: 1000px;
    margin: 0 auto;
}
.navItemBox{
    float: left;
}
.navItem{
    float: left;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    color:#FFFFFF;
    text-align: center;
    padding: 0 27px;
    cursor: pointer;
    text-decoration: none;
}
.navItemBox:first-child .navItem{
    padding: 0 27px 0 0;
}
.navItemText{
    color:#FFFFFF;
}
.navItem:hover {
    text-decoration: none;
}
.navItem:hover .navItemText{
    color: #0ED2F7;
    text-decoration: none;
}

.active .navItemText{
    font-weight: bold;
    color: #0ED2F7;
}


.navDot{
    float: left;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
}
