.lineHead{
    height: 45px;
    border-left:5px solid #092F91;
    background: #F7F7F7;
    overflow: hidden;
}
.lineHeadTitle{
    float: left;
    font-weight: bold;
    font-size: 16px;
    line-height: 45px;
    color: #212223;
    padding-left: 10px;
}
.lineHeadMore{
    float:right;
    margin-right: 5px;
    padding-right: 12px;
    height: 45px;
    line-height: 47px;
    font-size: 12px;
    color: #212223;
    background:url(https://x0.ifengimg.com/ucms/2022_38/730474E3A3E590838F20CA78B2C38C14C015A47C_size0_w12_h12.png) no-repeat right center;
}
.lineHeadMore:hover{
    text-decoration: underline;
    color: #212223;
}
