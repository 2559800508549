.organ{
    margin-top: 20px;
    width: 1000px;
    overflow: hidden;
}

.organBox{
    overflow: hidden;
    width:1014px;
}
.organPic{
    width: 320px;
    float: left;
    margin:20px 16px 0 0;
    border: 1px solid #E8E8E8;
}
