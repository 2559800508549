.news{
    width: 480px;
    float: right;
    margin-top: 20px;
}
.newsBoxItem{
    line-height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.newsBoxItem a{
    font-size: 16px;
    color: #212223;
}
.newsBoxItem a:hover{
    color: #092F91;
}
