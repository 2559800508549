
.layout{
    margin: 0 auto;
    width: 1050px;
}
.column{
    margin: 0 auto;
    width: 1000px;
    overflow: hidden;
}
.banner{
    height: 320px;
}
:global {
    & body {
        background-position: top center;
        background-repeat: no-repeat;
    }
}
.columnLeft{
    float: left;
    margin-top: 20px;
    width: 640px;
}
.columnRight{
    float: right;
    margin-top: 20px;
    width: 300px
}



