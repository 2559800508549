.schedule{
    margin-top: 20px;
}
.scheduleHead{
    width: 640px;
    height: 30px;
    background: #E5FAFF;
    overflow: hidden;
}
.scheduleTime{
    width: 140px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #212223;
    float: left;
}
.scheduleTheme{
    width: 500px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #212223;
    float: left;
}
.scheduleBoxScroll{
    height: 430px;
    overflow-y: scroll;
}
.scheduleBoxScroll::-webkit-scrollbar {
    display: block;
  }
.scheduleTr{
    height: 80px;
    overflow: hidden;
}
.scheduleTr:nth-child(even){
    background:rgba(247, 247, 247, .7);
}
.scheduleTd1{
    width: 140px;
    padding-top: 22px;
    float: left;
}
.scheduleTd2{
    float: left;
    width: 500px;
    height: 80px;
    line-height: 80px;
    display: flex;
    align-items: center;
}
.scheduleTdTime{
    font-size: 14px;
    line-height: 20px;
    color: #212223;
    text-align: center;
}
.scheduleTd2Text{
    line-height: 20px;
    max-height: 60px;
    overflow: hidden;
    font-size: 14px;
    color: #212223;
}
.scheduleScroll{
    margin-top: 10px;
    height: 430px;
    overflow-y: scroll;
}

.schedulePic{
    width: 100%;
    vertical-align: top;
}
