/* .wrap {
    border-top: 1px solid #E8E8E8;
}

.footerLink {
    position: relative;
    padding-top: 15px;
    border-top: 1px solid #fff;
    overflow: hidden;
    color: #000;
    line-height: 28px;
    font-size: 12px;
    text-align: center;
}

.footerLink a {
    padding: 0 7px;
    border-right: 1px solid #000;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #212223;
    text-align: center;
}

.footerLink a.nolpad {
    padding-left: 0;
}

.footerLink a.noborder {
    border: none;
}

.copyright {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #212223;
    text-align: center;
    padding-bottom: 28px;
} */
.box {
    width: 1000px;
    height: 90px;
    float: right;
    text-align: center;
    line-height: 90px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}
.footer {
    background: none;
    width: 100%;
    margin: 50px auto 0;
    height: 80px;
}
